export const Colors = {
  brand: {
    primary: '#444CF0',
    secondary: '#344b98',
    dark: '#343955',
    light: '#F4F8FD',
    black: '#202020',
    white: '#FFFFFF',
  },
  system: {
    error: '#e74c3c',
    warning: '#febc30',
    success: '#2ecc71',
  },
  basic: {
    black: '#000000',
    dark: '#202020',
    ground: '#777777',
    gray: '#C7C7C7',
    cloud: '#F2F2F2',
    white: '#FFFFFF',
  },
}
