import Script from 'next/script'
import { GA_TRACKING_ID } from '@lib/gtag'

const GoogleAnalytics = () => {
  return (
    <>
      <Script
        id="ga-setup"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="ga-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}');`,
        }}
      />
    </>
  )
}

export default GoogleAnalytics
