import { css } from 'styled-components'
import { Breakpoints } from './breakpoints'

const desktop = css`
  .h2 {
    font-size: 40px;
    font-weight: 600;
  }
  .h3 {
    font-size: 32px;
    font-weight: 600;
  }
`

const mobile = css`
  .h2 {
    font-size: 36px;
    font-weight: 600;
  }
  .h3 {
    font-size: 24px;
    font-weight: 600;
  }
`

export const Typography = css`
  ${desktop}
  @media (max-width: ${Breakpoints.mobilePortrait}) {
    ${mobile}
  }
`
