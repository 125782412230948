import { useEffect } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'
import type { AppProps } from 'next/app'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import * as gtag from '@lib/gtag'
import * as fbq from 'common/lib/fpixel'
import GoogleAnalytics from '@components/googleAnalytics'

import { motion, AnimatePresence } from 'framer-motion'
import { GlobalStyle } from 'styles'
import 'styles/guides/utilities.css'
import 'styles/global.css'
import { Toaster } from '@components/ui/toaster'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    // verificar se precisa colocar o gtag
    fbq.pageview()

    const handleRouteChange = (url: URL) => {
      gtag.pageview(url)
      fbq.pageview()
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.RECAPTCHA_SITE_KEY || ''}
      scriptProps={{
        async: false, // optional, default to false,
        defer: true, // optional, default to false
        appendTo: 'body', // optional, default to "head", can be "head" or "body",
        nonce: undefined,
      }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          key={router.route}
          initial="initialState"
          animate="animateState"
          exit="exitState"
          transition={{
            duration: 0.5,
          }}
          variants={{
            initialState: {
              opacity: 0,
            },
            animateState: {
              opacity: 1,
            },
            exitState: {},
          }}
        >
          <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
            }}
          />
          <GlobalStyle />
          <Toaster />
          <Component {...pageProps} />
          <GoogleAnalytics />
        </motion.div>
      </AnimatePresence>
    </GoogleReCaptchaProvider>
  )
}

export default MyApp
