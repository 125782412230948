import { createGlobalStyle } from 'styled-components'
import { Breakpoints } from './breakpoints'

export const GlobalStyle = createGlobalStyle`
html,
body {
  padding: 0;
  margin: 0;
  color: #202020;
  font-family: Poppins, -apple-system, Helvetica Neue;
  line-height: 1.5;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.h2 {
    font-size: 40px;
    font-weight: 600;
    @media (max-width: ${Breakpoints.mobilePortrait}) {
      font-size: 36px;
  }
  }
.h3 {
    font-size: 32px;
    font-weight: 600;
    @media (max-width: ${Breakpoints.mobilePortrait}) {
      font-size: 24px;
  }
  }
  
  @media (prefers-color-scheme: light) {
    html {
      color-scheme: dark;
    }
    body {
      color: white;
      background: black;
    }
  }


`
