export const FB_PIXEL_ID = process.env.FB_PIXEL_ID

export const pageview = () => {
  window.fbq('track', 'PageView')
}

export const custom = () => {
  window.fbq('track', 'Lead')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  window.fbq('track', name, options)
}
